import React from 'react'
import tick from '../../../Assets/homepage/green_tick.svg'
import playstore from '../../../Assets/homepage/playstore.png'
import gif from '../../../Assets/homepage/essGif.gif'

const EssSection = () => {
    return (
        <>
            <div className='flex flex-col md:flex-row justify-between items-center'>
                <div className='flex flex-col gap-5 justify-center w-full md:w-1/2 p-10'>
                    <h1 className='text-blue font-bold text-3xl md:text-5xl'>Token <span className='text-lightPurple'>Talent</span></h1>
                    <h2 className='text-black text-xl md:text-3xl font-semibold'>Employee Self-Service (ESS) <br />Mobile App</h2>
                    <p className='text-xs md:text-sm'>
                        Using Token Talent's ESS mobile application, employees and managers can
                        stay connected wherever they go. They will also have access to salary slips,
                        leave and vacation approvals and requests, automated time and attendance
                        tracking, and work schedules and shifts all at their fingertips.
                    </p>
                    <p className='flex gap-2 items-center text-xs font-medium'><img src={tick} alt="" />Give your employees access to salary slips</p>
                    <p className='flex gap-2 items-center text-xs font-medium'><img src={tick} alt="" />Automated attendance and time-tracking with geolocation</p>
                    <p className='flex gap-2 items-center text-xs font-medium'><img src={tick} alt="" />Manage employee's leave and vacation requests</p>
                    <p className='flex gap-2 items-center text-xs font-medium'><img src={tick} alt="" />Employee scheduling and shift management</p>
                    <p className='flex gap-2 items-center text-xs font-medium'><img src={tick} alt="" />Give your employees access to their calendar so they can see holiday & their work schedule</p>
                    <img src={playstore} alt='' className='w-32 md:w-40 cursor-pointer' />
                </div>
                <div className='w-full md:w-1/2 flex justify-end'>
                    <img src={gif} alt='' className='max-w-full md:max-w-md mt-0 sm:mt-28' />
                </div>
            </div>
        </>
    )
}

export default EssSection
