import React, { useEffect } from 'react'
import HomecarouselCardSec from '../../Pages/HomePage/HomeCarouselCard/HomecarouselCardSec'
import HeaderSection from '../../Pages/HomePage/HeaderSection/HeaderSection'
import HomePageCardSection from '../../Pages/HomePage/HomePageCardSection/HomePageCardSection'
import HomeInternationTeam from '../../Pages/HomePage/HomeInternationTeam/HomeInternationTeam'
import HomeGlobalTeam from '../../Pages/HomePage/HomeGlobalTeam/HomeGlobalTeam'
import groupImg from '../../Assets/homepage/GroupImg.png'
import HomeHrSection from './HomeHrSection/HomeHrSection'
import EssSection from './EssSection/EssSection'
import HomeReasonSection from './HomeReasonSection/HomeReasonSection'

const HomePage = () => {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    }, []);

    return (
        <>
            <HeaderSection />
            <HomePageCardSection />
            <HomecarouselCardSec />
            <div className='bg-lightGray flex justify-center items-center p-10'>
                <img src={groupImg} className='md:max-w-2xl w-full mx-auto' />
            </div>
            <HomeInternationTeam />
            <HomeGlobalTeam />
            <HomeHrSection />
            <EssSection />
            <HomeReasonSection />
        </>
    )
}

export default HomePage
