import React from 'react';
import UserNeedCards from '../../../Components/Atomic/UserNeedCards';
import user from '../../../Assets/featuresPage/user.svg';
import fingerPrint from '../../../Assets/featuresPage/fingerPrint.svg';
import file from '../../../Assets/featuresPage/file.svg';
import calendar from '../../../Assets/featuresPage/calendar.svg';
import salary from '../../../Assets/featuresPage/salary.svg';
import policies from '../../../Assets/featuresPage/policies.svg';

const UserNeedCardsSec = () => {
    return (
        <>
            <div className='bg-white flex items-center justify-center p-8'>
                <h1 className='font-bold text-3xl text-black text-center'>
                    Prioritize User Needs <span className='text-blue'>At Every Step</span>
                </h1>
            </div>
            <div className='bg-white grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 p-1 pt-1'>
                <UserNeedCards
                    icon={user}
                    heading='Profile Administration'
                    text='Efficient management of employee profiles & related information.'
                    bg='#FFEDE7'
                />
                <UserNeedCards
                    icon={fingerPrint}
                    heading='Attendance'
                    text='Accurate tracking of employee attendance & work hours.'
                    bg='#CAFFC9'
                />
                <UserNeedCards
                    icon={file}
                    heading='Leave'
                    text='Streamlined process for managing employee leave requests.'
                    bg='#FDD9FF'
                />
                <UserNeedCards
                    icon={calendar}
                    heading='Calendar'
                    text='Structured scheduling and management of employee holidays.'
                    bg='#FFE0AB'
                />
                <UserNeedCards
                    icon={salary}
                    heading='Salary'
                    text='Systematic recording & access to employee salary information.'
                    bg='#E0EFFF'
                />
                <UserNeedCards
                    icon={policies}
                    heading='Policies'
                    text='Guidelines & procedures for employee departure & termination.'
                    bg='#DCDBF9'
                />
            </div>
        </>
    );
};

export default UserNeedCardsSec;
