import React, { useEffect } from 'react'
import leaveIcon from '../../Assets/solution/leave/Group 119.svg'
import leave from '../../Assets/solution/leave/leave 1.png'

const FeatureSection = ({ image, title, description, imageOnLeft = true }) => (
    <div className={`flex flex-col ${imageOnLeft ? 'md:flex-row' : 'md:flex-row-reverse'} items-center gap-8 mb-16`}>
        <div className="w-full md:w-1/2 bg-lightPurple p-6 rounded-2xl">
            <img src={image} alt={title} className="w-full h-auto rounded-lg shadow-md" />
        </div>
        <div className="w-full md:w-1/2 space-y-4">
            <img src={leaveIcon} alt="" className="h-8 w-8" />
            <h3 className="text-sm font-semibold text-gray-600 uppercase">LEAVE MANAGEMENT</h3>
            <h2 className="text-3xl font-bold">{title}</h2>
            <p className="text-gray-700">{description}</p>
        </div>
    </div>
)
const LeaveManagement = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    }, []);
    return (
        <>
            <div className="container mx-auto p-10">
                <h1 className="text-4xl font-bold text-center mb-12">Leave Management</h1>
                <FeatureSection
                    image={leave}
                    title="Leave System"
                    description="At Token Talent, we believe time is the most valuable resource we have. Our wide array of products focus on employee timesheet management as well as scheduling to assure your business is running at its maximum potential. Token Talent partners with device manufacturers to provide cross-platform compatibility with time tracking devices."
                />

                <div className="mt-16 space-y-8">
                    <div className="space-y-6">
                        <div>
                            <ul className="list-disc list-inside mb-2">
                                <h3 className="text-xl font-semibold mb-2">Set your team's monthly leave</h3>
                                <li className="text-gray-700 text-sm">Easily set your work schedules per Department or Employee or track an employee's timesheets over time.
                                    Whether you're a restaurant or a textile company, we know that you may have different tracking methods,
                                    so we're constantly striving on improving our features so we're always opened for feedback at info@tokentalent.co</li>
                            </ul>
                        </div>
                        <div>
                            <ul className="list-disc list-inside mb-2">
                                <h3 className="text-xl font-semibold mb-2">Timesheets feed into Payroll</h3>
                                <li className="text-gray-700 text-sm">Be confident knowing all of your Employees information is in one safe place where you can quickly make edits
                                    to their Personal and Business information.</li></ul>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold mb-2">Integrate with your leave Device</h3>
                            <ul className="list-disc list-inside mb-2">
                                <li className="text-gray-700 text-sm">If you're already using a biometric device, check in with our team so we can properly connect your data from your Time
                                    Tracking/Biometric Devices with Token Talent</li></ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LeaveManagement
