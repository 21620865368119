import React, { useEffect } from 'react'
import people from '../../Assets/solution/people-management/6359a6f658142660c57d3511_Vector 1.svg'
import dashboardPic from '../../Assets/solution/people-management/people-dashboard.png'
import employeePic from '../../Assets/solution/people-management/employee.png'

const FeatureSection = ({ image, title, description, imageOnLeft = true }) => (
    <div className={`flex flex-col ${imageOnLeft ? 'md:flex-row' : 'md:flex-row-reverse'} items-center gap-8 mb-16`}>
        <div className="w-full md:w-1/2 bg-lightPurple p-6 rounded-2xl">
            <img src={image} alt={title} className="w-full h-auto rounded-lg shadow-md" />
        </div>
        <div className="w-full md:w-1/2 space-y-4">
            <img src={people} alt="" className="h-8 w-8" />
            <h3 className="text-sm font-semibold text-gray-600 uppercase">Organization Management</h3>
            <h2 className="text-3xl font-bold">{title}</h2>
            <p className="text-gray-700">{description}</p>
        </div>
    </div>
)
const PeopleManagement = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    }, []);
    return (
        <div className="container mx-auto p-10">
            <h1 className="text-4xl font-bold text-center mb-12">People Management</h1>

            <FeatureSection
                image={employeePic}
                title="Employee Directory + More"
                description="A well-structured organization is key to a healthy business. Our Organization Management feature helps you manage all your employees with a click of a button! Store important data such as salary and paid time off information in one secure place."
            />

            <FeatureSection
                image={dashboardPic}
                title="Organization Chart"
                description="Visualize your company structure with our intuitive Organization Chart. Easily manage departments, teams, and reporting lines. Keep your organizational hierarchy up-to-date and accessible to all relevant stakeholders."
                imageOnLeft={false}
            />

            <div className="mt-16 space-y-8">
                <div className="space-y-6">
                    <div>
                        <ul className="list-disc list-inside mb-2">
                            <h3 className="text-xl font-semibold mb-2">Beautiful Layout, Easy on the Eyes</h3>
                            <li className="text-gray-700 text-sm">Easily keep all of your Employee's information in one place for safekeeping. Quickly make edits where you can
                                easily add employees or off-board employees.</li>
                        </ul>
                    </div>
                    <div>
                        <ul className="list-disc list-inside mb-2">
                            <h3 className="text-xl font-semibold mb-2">Keep Track of Employee Info</h3>
                            <li className="text-gray-700 text-sm">Be confident knowing all of your Employees information is in one safe place where you can quickly make edits to
                                their Personal and Business information. Manage Contractors, Part-Time, Full-Time and Seasonal Workers.</li></ul>
                    </div>
                    <div>
                        <h3 className="text-xl font-semibold mb-2">Manage Departments, Work Schedules & PTO</h3>
                        <ul className="list-disc list-inside mb-2">
                            <li className="text-gray-700 text-sm">Use our People directory to easily keep track of your Departments, Branches, Work Schedules and Paid Time Off balances
                                Policies across your Departments. Easily assign or change Titles and reorganise as you wish!</li></ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PeopleManagement