// CarouselCard.js
import React from 'react';
import rightArrow from '../../Assets/homepage/rightArrow.svg';
import userRound from '../../Assets/homepage/userRound.svg';

const CarouselCard = ({ bg, paragraph, name }) => {
    return (
        <>
            <section className="bg-lightGray p-10">
                <div className="container ">
                    <div className="flex">
                        <div className="h-full p-8 rounded-xl" style={{ backgroundColor: bg }}>
                            <p className="text-xs mb-5 text-white">{paragraph}</p>
                            <button className='text-white text-sm underline flex gap-2 items-center'>Read full story <img src={rightArrow} alt="" /></button>
                            <a className="flex items-center mt-4">
                                <img alt="" src={userRound} className="w-12 h-12 flex-shrink-0 object-cover object-center" />
                                <span className="flex-grow flex justify-between pl-2 items-center">
                                    <span className="font-medium text-white">{name}</span>
                                    <span className="font-bold text-2xl text-white">TTL</span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CarouselCard;
