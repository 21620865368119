import React from 'react';

const UserNeedCards = ({ icon, heading, text, bg }) => {
    return (
        <>
            <div className='p-5'>
                <div className='border shadow-md rounded-xl flex flex-col justify-between p-3'>
                    <div className='flex items-center justify-center rounded-full h-9 w-9 mb-4 shadow-md' style={{ backgroundColor: bg }}>
                        <img src={icon} alt="" className='h-6 w-6' />
                    </div>
                    <h1 className='font-semibold text-xs'>{heading}</h1>
                    <p className='text-xs mt-2'>{text}</p>
                </div>
            </div>
        </>
    );
};

export default UserNeedCards;
