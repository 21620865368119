import React from 'react'
import OnboardCards from '../../../Components/Atomic/OnboardCards'
import pic1 from '../../../Assets/featuresPage/onboard1.png'
import pic2 from '../../../Assets/featuresPage/onboard2.png'
import pic3 from '../../../Assets/featuresPage/onboard3.png'
import pic4 from '../../../Assets/featuresPage/onboard4.png'
import pic5 from '../../../Assets/featuresPage/onboard5.png'

const OnboardSection = () => {
    return (
        <>
            <div className='p-12'>
                <div className='flex justify-center items-center flex-col text-center gap-3'>
                    <h1 className='font-bold text-3xl'>Onboard in a <span className='text-blue'>few clicks</span></h1>
                    <p className='text-xs'>Go from “I'm ready to hire” to “Welcome aboard!” in minutes with our seamless flows that eliminate <br className='hidden md:block' /> friction and guesswork.</p>
                </div>
            </div>
            <div className='bg-white grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-8 p-10 pt-3'>
                <OnboardCards
                    heading={<>Add, <span className='text-lightPurple'>invite,</span><span className='text-blue'> done</span></>}
                    text={"Add and invite your employees one at a time or via bulk upload, providing basic details about them and their job. This usually takes 5 minutes or less."}
                    image={pic2} />
                <OnboardCards
                    heading={<>Automatic, <span className='text-lightPurple'>invites</span></>}
                    text={"Hires are cared for every step of the way with an automatic email invite to join your team on Remote and instant access to their own employee account."}
                    image={pic3} />
                <OnboardCards
                    heading={<>Custom <span className='text-lightPurple'>onboarding</span><span className='text-blue'><br />checklists</span></>}
                    text={"Each hire gets a custom onboarding checklist to complete at their pace; employers have full visibility to track their progress."}
                    image={pic4} />
                <OnboardCards
                    heading={<>Add, <span className='text-lightPurple'>invite,</span><span className='text-blue'> done</span></>}
                    text={"Add and invite your employees one at a time or via bulk upload, providing basic details about them and their job. This usually takes 5 minutes or less."}
                    image={pic5} />
                <OnboardCards
                    heading={<><span className='text-lightPurple'>Offboarding</span> too</>}
                    text={"When the time comes to say goodbye to an employee, you can expect the same delightful, streamlined experience for off boarding"}
                    image={pic1} />
            </div>
        </>
    )
}

export default OnboardSection
