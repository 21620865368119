import React from 'react'
import screen from '../../../Assets/featuresPage/screen.png'

const TourSection = () => {
    return (
        <>
            <div className='p-12'>
                <div className='flex justify-center items-center flex-col text-center gap-3'>
                    <h1 className='font-bold text-3xl'>Take the<span className='text-lightPurple'> tour</span></h1>
                    <p className='text-xs'>See just how easy it is to manage your entire global workforce in one place.</p>
                </div>
                <div className='flex justify-center items-center mt-5'>
                    <img src={screen} alt="" className='w-full md:max-w-2xl object-cover' />
                </div>
            </div>
        </>
    )
}
export default TourSection
