import React from 'react'
import Header from '../../../Components/Header/Header'
import headerImg from '../../../Assets/featuresPage/header-img.png'

const HeaderSection = () => {
    return (
        <>
            <Header
                heading={
                    <>
                        Devote yourself to <br /> your most valuable <br /> creation
                    </>
                }
                featureImage={headerImg} />
        </>
    )
}

export default HeaderSection
