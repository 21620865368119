import React from 'react'

const Cards = ({ icon, heading, text }) => {
    return (
        <>
            <div className='card'>
                <div className='flex justify-center items-center flex-col'>
                    <img src={icon} alt="" className='h-12 w-12 mb-8' />
                    <h1 className='font-bold text-2xl'>{heading}</h1>
                    <p className='text-xs mt-4'>{text}</p>
                </div>
            </div>
        </>
    )
}

export default Cards
