import React, { useEffect } from 'react'
import payrollIcon from '../../Assets/solution/payroll/SVGRepo_iconCarrier.svg'
import payroll from '../../Assets/solution/payroll/payroll 1.png'

const FeatureSection = ({ image, title, description, imageOnLeft = true }) => (
    <div className={`flex flex-col ${imageOnLeft ? 'md:flex-row' : 'md:flex-row-reverse'} items-center gap-8 mb-16`}>
        <div className="w-full md:w-1/2 bg-lightPurple p-6 rounded-2xl">
            <img src={image} alt={title} className="w-full h-auto rounded-lg shadow-md" />
        </div>
        <div className="w-full md:w-1/2 space-y-4">
            <img src={payrollIcon} alt="" className="h-8 w-8" />
            <h3 className="text-sm font-semibold text-gray-600 uppercase">PAYROLL MANAGEMENT</h3>
            <h2 className="text-3xl font-bold">{title}</h2>
            <p className="text-gray-700">{description}</p>
        </div>
    </div>
)

const PayrollManagement = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    }, []);
    return (
        <>
            <div className="container mx-auto p-10">
                <h1 className="text-4xl font-bold text-center mb-12">Payroll Management</h1>
                <FeatureSection
                    image={payroll}
                    title="Employee Directory + More"
                    description="A well-structured organization is key to a healthy business. One of our most prominent features we offer is Organization Management which can help you manage all your employees with a click of a button! Store all of their important data such as salary and paid time off information in one safe place."
                />
                <div className="mt-16 space-y-8">
                    <div className="space-y-6">
                        <div>
                            <ul className="list-disc list-inside mb-2">
                                <h3 className="text-xl font-semibold mb-2">Sync Payroll with Attendance Tracking</h3>
                                <li className="text-gray-700 text-sm">Token Talent Payroll Studio allows you to easily sync Time Attendance. While running payroll, sync and review Hours Worked,
                                    Paid Time Off, and Time Deductions</li>
                            </ul>
                        </div>
                        <div>
                            <ul className="list-disc list-inside mb-2">
                                <h3 className="text-xl font-semibold mb-2">Accessible Payroll Reports + Payslips</h3>
                                <li className="text-gray-700 text-sm">Easily access Payroll Reports for years with backups stored. After Payroll is run is submitted, Token Talent allows you to download
                                    Payslips so your Employees can have access to their Net Pay to Date.</li></ul>
                        </div>
                        <div>
                            <h3 className="text-xl font-semibold mb-2">Upcoming Banking Integrations</h3>
                            <ul className="list-disc list-inside mb-2">
                                <li className="text-gray-700 text-sm">Token Talent prides itself in constantly taking feedback from customers, and thus we are working on several exciting Banking
                                    Integrations to make the HR/Payroll process easier for you and your business.</li></ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PayrollManagement
