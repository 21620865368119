import React, { useEffect } from 'react'

const HiringPage = () => {
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    }, []);
    return (
        <div>
            <h2 className="text-xl text-center sm:text-3xl lg:text-5xl font-bold flex justify-center items-center h-screen text-blue">No vacancies are available now. 🤧</h2>
        </div>
    )
}

export default HiringPage
