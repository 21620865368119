import React from 'react';
import CarouselCard from '../../../Components/Atomic/CarouselCard';
import Slider from "react-slick";  // Import react-slick
import "slick-carousel/slick/slick.css";  // Import slick-carousel styles
import "slick-carousel/slick/slick-theme.css";  // Import slick-carousel theme
import './HomecarouselCardSec.css'; // Import the custom CSS

const HomecarouselCardSec = () => {
    // Slick slider settings
    const settings = {
        infinite: true,
        autoplay: true,
        speed: 900,
        slidesToShow: 2.5,  // Show 2 full cards and part of the 3rd
        autoplaySpeed: 2000,
        slidesToScroll: 1,  // Scroll 1 card at a time
        responsive: [
            {
                breakpoint: 1024, // At 1024px or less, adjust the number of slides
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 600, // At 600px or less, adjust the number of slides
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    return (
        <div className="Container">
            <Slider {...settings}>
                <div>
                    <CarouselCard
                        bg={'#1260FD'}
                        paragraph={"Hi, anything that's country-specific, it's possible that somethin will get missed and problems can happen. Remote has it covered with the correct contracts, payroll, taxes, benefits and everything else along the way. That peace of mind is everything."}
                        name='James' />
                </div>
                <div>
                    <CarouselCard
                        bg={'#0B8900'}
                        paragraph={"Hi, anything that's country-specific, it's possible that somethin will get missed and problems can happen. Remote has it covered with the correct contracts, payroll, taxes, benefits and everything else along the way. That peace of mind is everything."}
                        name='James' />
                </div>
                <div>
                    <CarouselCard
                        bg={'#D44633'}
                        paragraph={"Hi, anything that's country-specific, it's possible that somethin will get missed and problems can happen. Remote has it covered with the correct contracts, payroll, taxes, benefits and everything else along the way. That peace of mind is everything."}
                        name='James' />
                </div>
                <div>
                    <CarouselCard
                        bg={'#F39800'}
                        paragraph={"Hi, anything that's country-specific, it's possible that somethin will get missed and problems can happen. Remote has it covered with the correct contracts, payroll, taxes, benefits and everything else along the way. That peace of mind is everything."}
                        name='James' />
                </div>
                <div>
                    <CarouselCard
                        bg={'#353535'}
                        paragraph={"Hi, anything that's country-specific, it's possible that somethin will get missed and problems can happen. Remote has it covered with the correct contracts, payroll, taxes, benefits and everything else along the way. That peace of mind is everything."}
                        name='James' />
                </div>
            </Slider>
        </div>
    );
};

export default HomecarouselCardSec;
