import React from 'react';
import dashboard from '../../../Assets/homepage/Dashbaord.png';
import mob from '../../../Assets/homepage/mob.png';
import tick from '../../../Assets/homepage/mdi_tick-circle.svg';
import './HomeHrSection.css';

const HomeHrSection = () => {
    return (
        <div className="bg-gray-100 py-12 px-4 sm:px-6 lg:px-2">
            <h1 className="text-blue text-center text-3xl sm:text-4xl font-bold mb-4">
                HR <span className="text-black">Anywhere</span>
            </h1>
            <p className="text-xs text-center max-w-2xl mx-auto mb-12">
                Run payroll seamlessly across borders with timely and compliant payments
                to your entire team, no matter where they're located.
            </p>

            <div className="flex flex-col lg:flex-row justify-center gap-8">
                <Card
                    title="Employer"
                    bgColor="bg-lightGreen"
                    description="Run payroll seamlessly across borders with timely and compliant payments to your entire team, no matter where they're located."
                    items={[
                        "Give your employees access to salary slip",
                        "Manage employee data efficiently",
                        "Generate comprehensive reports",
                        "Ensure compliance with local regulations"
                    ]}
                    image={dashboard}
                    imageClass="dashboard"
                />

                <Card
                    title="Employee"
                    bgColor="bg-lightBlue"
                    description="Access your payroll information and benefits with ease, anytime and anywhere."
                    items={[
                        "View and download salary slips",
                        "Track your work hours and leave balance",
                        "Access important company documents",
                        "Update personal information",
                        "Communicate with HR efficiently"
                    ]}
                    image={mob}
                    imageClass="mobile"
                />
            </div>
        </div>
    );
};

const Card = ({ title, bgColor, description, items, image, imageClass }) => (
    <div className={`cards w-full lg:w-[30%] ${bgColor} rounded-3xl p-5 text-white relative overflow-hidden`}>
        <div className="z-10 relative">
            <h2 className="text-lg font-semibold mb-3">{title}</h2>
            <p className="text-xs mb-3">{description}</p>
            <div className="mb-1">
                {items.map((item, index) => (
                    <div key={index} className="flex items-start gap-2 mb-2">
                        <img src={tick} alt="" className="w-3 h-3 flex-shrink-0 mt-0.5" />
                        <p className="text-xs">{item}</p>
                    </div>
                ))}
            </div>
        </div>
        <div className={`image-container ${imageClass}`}>
            <img src={image} alt={title} className="w-full h-auto" />
        </div>
    </div>
);

export default HomeHrSection;