import React from 'react'
import mob from '../../../Assets/featuresPage/lastIphonePic.png'
import './LastSection.css'

const LastSection = () => {
    return (
        <>
            <div className='last flex flex-col md:flex-row justify-between items-center mt-5'>
                <div className='flex flex-col gap-5 justify-center w-full md:w-1/2 p-14'>
                    <p className='text-md md:text-2xl lg:text-4xl font-bold text-white md:text-left text-center'>
                        The HR platform is easy to use and everything is there right in front of us. Because everything comes together and we're not having to update different sources.</p>
                </div>
                <div className='w-full md:w-1/2 flex justify-end'>
                    <img src={mob} alt='' className='w-full md:max-w-md mt-0 sm:mt-28' />
                </div>
            </div>
        </>
    )
}

export default LastSection
