import React from 'react'

const OnboardCards = ({ heading, text, image }) => {
    return (
        <div className='card border rounded-2xl p-3 shadow-md flex flex-col justify-between h-full'>
            <div className='flex flex-col gap-3'>
                <h1 className='font-semibold text-xl'>{heading}</h1>
                <p className='text-xs'>{text}</p>
            </div>
            <div className='mt-4'>
                <img src={image} alt="" className='w-full object-cover' />
            </div>
        </div>
    )
}

export default OnboardCards