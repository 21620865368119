import React from 'react'
import tick from '../../../Assets/homepage/green_tick.svg'
import arrow from '../../../Assets/homepage/green-arrow.svg'
import employee from '../../../Assets/homepage/clarity_employee-group-solid.svg'
import checked from '../../../Assets/homepage/wpf_security-checked.svg'
import dollar from '../../../Assets/homepage/fa6-solid_sack-dollar.svg'
import './HomeReasonSection.css'

const HomeReasonSection = () => {
    return (
        <>
            <div className='reason p-10 sm:p-8 md:p-12 text-white flex flex-col justify-center items-center gap-3'>
                <h1 className='font-bold text-2xl sm:text-3xl md:text-4xl text-center'>
                    TECHNOLOGY WITH THE POWER TO TRANSFORM HR
                </h1>
                <p className='text-sm sm:text-base md:text-md font-semibold text-center'>
                    Tackling your HR needs from Acquire to Retire
                </p>
                <div className='flex flex-col sm:flex-row gap-3 mt-3'>
                    <a target='_blank' rel='noopener noreferrer' href="https://tokentalent.co/signup">
                        <button onClick={(e) => {
                            e.preventDefault();
                            window.open("https://tokentalent.co/signup", "_blank");
                        }} className='bg-white text-blue w-32 p-2 sm:p-3 rounded-full text-xs font-medium'>
                            Get Started
                        </button>
                    </a>
                    <a target='_blank' rel='noopener noreferrer' href="https://calendly.com/demofortokentalent/token-talent-demo">
                        <button onClick={(e) => {
                            e.preventDefault();
                            window.open("https://calendly.com/demofortokentalent/token-talent-demo", "_blank");
                        }} className='bg-darkBlue text-white w-32 p-2 sm:p-3 shadow-lg  rounded-full text-xs font-normal'>
                            Let's Talk
                        </button>
                    </a>
                </div>
                <div className='flex justify-start items-start flex-col gap-2 text-xs mt-3 text-center'>
                    <p className='flex items-center gap-2 justify-center'>
                        <img src={tick} alt="" /> Hassle-free sign-up process
                    </p>
                    <p className='flex items-center gap-2 justify-center'>
                        <img src={tick} alt="" /> Amazing after-sales support
                    </p>
                    <p className='flex items-center gap-2 justify-center'>
                        <img src={tick} alt="" /> Fully localized HR system
                    </p>
                </div>
                <p className='text-sm sm:text-base md:text-md font-semibold mt-3 text-center'>
                    Reasons to switch to a HR software like Token Talent
                </p>
                <div className='mt-3 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4'>
                    <div className='border-2 border-white p-4 gap-3 rounded-xl flex flex-col justify-center items-center'>
                        <div className='bg-white rounded-xl p-2 flex items-center'>
                            <img src={employee} alt="" />
                        </div>
                        <h1 className='font-semibold text-center'>Engagement</h1>
                        <p className='text-xs text-center'>Increase in employee engagement</p>
                        <h1 className='font-bold text-3xl flex items-center gap-1'>
                            <img src={arrow} alt="" /> 67%
                        </h1>
                    </div>
                    <div className='border-2 border-white p-4 gap-3 rounded-xl flex flex-col justify-center items-center'>
                        <div className='bg-white rounded-xl p-2 flex items-center'>
                            <img src={dollar} alt="" />
                        </div>
                        <h1 className='font-semibold text-center'>Time & Cost</h1>
                        <p className='text-xs text-center'>Increase in time & budget savings</p>
                        <h1 className='font-bold text-3xl flex items-center gap-1'>
                            <img src={arrow} alt="" /> 88%
                        </h1>
                    </div>
                    <div className='border-2 border-white p-4 gap-3 rounded-xl flex flex-col justify-center items-center'>
                        <div className='bg-white rounded-xl p-2 flex items-center'>
                            <img src={checked} alt="" />
                        </div>
                        <h1 className='font-semibold text-center'>Security</h1>
                        <p className='text-xs text-center'>Increase in security and compliance</p>
                        <h1 className='font-bold text-3xl flex items-center gap-1'>
                            <img src={arrow} alt="" /> 78%
                        </h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeReasonSection
