import React from 'react'
import Cards from '../../../Components/Atomic/Cards'
import zoom from '../../../Assets/homepage/zoom.svg'
import user from '../../../Assets/homepage/user.svg'
import clipboard from '../../../Assets/homepage/clipboard.svg'
import arrow from '../../../Assets/homepage/arrow.svg'
import calculator from '../../../Assets/homepage/calculator.svg'

const HomePageCardSection = () => {
    return (
        <>
            <div className='bg-lightGray grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-6 p-16 justify-items-center'>
                <Cards
                    icon={zoom}
                    heading='Find'
                    text='The best person for the job could be anywhere in the world. Connect with top jobseekers for your open roles.' />
                <Cards
                    icon={clipboard}
                    heading='Hire'
                    text='The best person for the job could be anywhere in the world. Connect with top jobseekers for your open roles.' />
                <Cards
                    icon={calculator}
                    heading='Pay'
                    text='The best person for the job could be anywhere in the world. Connect with top jobseekers for your open roles.' />
                <Cards
                    icon={user}
                    heading='Manage'
                    text='The best person for the job could be anywhere in the world. Connect with top jobseekers for your open roles.' />
                <Cards
                    icon={arrow}
                    heading='Exit'
                    text='The best person for the job could be anywhere in the world. Connect with top jobseekers for your open roles.' />
            </div>
        </>
    )
}

export default HomePageCardSection
