import React from 'react'
import logo from '../../Assets/footer/logo.svg'
import certified from '../../Assets/footer/certified.svg'
import facebook from '../../Assets/footer/facebook.svg'
import instagram from '../../Assets/footer/instagram.svg'
import linkedin from '../../Assets/footer/linkedin.svg'
import youtube from '../../Assets/footer/youtube.svg'
import twitter from '../../Assets/footer/twitter.svg'
import tiktok from '../../Assets/footer/tiktok.svg'
import star from '../../Assets/footer/star.svg'
import mail from '../../Assets/footer/mail.svg'
import g2 from '../../Assets/footer/g2.svg'
import uk from '../../Assets/footer/flag.svg'
import locationArrow from '../../Assets/footer/locationArrow.png'
import pak from '../../Assets/footer/pak.svg'
import { useNavigate, Link } from 'react-router-dom'

const Footer = () => {
    const navigate = useNavigate();

    const icons = [
        { icon: facebook, link: 'https://www.facebook.com/tokentalent' },
        { icon: linkedin, link: 'https://www.linkedin.com/company/tokentalent/mycompany/' },
        { icon: instagram, link: 'https://www.instagram.com/tokentalent/' },
        { icon: twitter, link: 'https://twitter.com/TokenTalent' },
        { icon: youtube, link: 'https://www.youtube.com/channel/UCosRk7zGP3T4JMDQEK8RMUg' },
        { icon: tiktok, link: 'https://www.tiktok.com/@tokentalenthr' },
    ];

    return (
        <>
            <footer className=" bg-darkBlue">
                <div className="container px-5 py-24 mx-auto flex md:items-center lg:items-start md:flex-row md:flex-nowrap flex-wrap flex-col">
                    <div className="w-64 flex-shrink-0 md:mx-0 mx-auto text-center md:text-left">
                        <a className="flex items-center md:justify-start justify-center text-white">
                            <img onClick={() => { navigate('/') }} src={logo} alt='' className='-mt-4 cursor-pointer' />
                        </a>
                        <div className='flex gap-2 mt-3'>
                            {icons.map((icon, index) => (
                                <a key={index} href={icon.link} target='_blank' rel='noopener noreferrer'>
                                    <img
                                        src={icon.icon}
                                        alt=''
                                        className='w-9 cursor-pointer'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open(icon.link, '_blank');
                                        }}
                                    />
                                </a>
                            ))}
                        </div>

                    </div>
                    <div className="flex-grow flex flex-wrap md:pl-20 -mb-10 md:mt-0 mt-10 md:text-left text-center">
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="font-semibold text-white text-sm mb-3">Token Talent</h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <Link to='/features' className="text-white text-xs cursor-pointer">Why Token Talent</Link>
                                </li>
                                <li>
                                    <Link to='/pricing' className="text-white text-xs cursor-pointer">Pricing</Link>
                                </li>
                                <li>
                                    <Link to='/hiring' className="text-white text-xs cursor-pointer">We're Hiring!</Link>
                                </li>
                            </nav>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 w-full px-10">
                            <h2 className="font-semibold text-white text-sm mb-3">Accounts</h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <a
                                        href='https://app.tokentalent.co/login'
                                        className="text-white text-xs cursor-pointer"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open('https://app.tokentalent.co/login', '_blank');
                                        }}
                                    >
                                        Login
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href='https://tokentalent.co/signup'
                                        className="text-white text-xs cursor-pointer"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.open('https://tokentalent.co/signup', '_blank');
                                        }}
                                    >
                                        Sign Up
                                    </a>
                                </li>

                            </nav>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="font-semibold text-white text-sm mb-3">Company</h2>
                            <nav className="list-none mb-10">
                                <li>
                                    <Link to='/termsandCondition' className="text-white text-xs cursor-pointer">Terms & Services</Link>
                                </li>
                                <li>
                                    <Link to='/privacypolicy' className="text-white text-xs cursor-pointer">Privacy Policies</Link>
                                </li>
                            </nav>
                        </div>
                        <div className="lg:w-1/4 md:w-1/2 w-full px-4">
                            <h2 className="font-semibold text-white text-xs mb-3 flex gap-1 justify-center items-center">
                                <img src={mail} className='w-5' />info@tokentalent.co</h2>
                            <nav className="list-none mb-10 flex justify-center items-center flex-col gap-2">
                                <li>
                                    <span className="text-white text-xs sm:justify-start justify-center">Trusted By</span>
                                </li>
                                <li>
                                    <img src={certified} alt='' />
                                    <div className='flex gap-2 items-center mt-3'>
                                        <img src={locationArrow} alt='' className='mt-0.5 w-5' />
                                        <img src={star} alt='' className='w-6' />
                                        <img src={g2} alt='' className='w-6' />
                                    </div>
                                </li>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col sm:flex-row sm:text-left text-center justify-between p-10 text-xs bg-lightPurple text-white'>
                    <div>
                        <p className='font-bold mb-3 flex gap-2 sm:justify-start justify-center items-center'>Token Talent, Pakistan <img src={pak} className='w-5' /></p>
                        <p>Arfa Karim Software Technology Park, Lahore, Punjab Pakistan</p>
                    </div>
                    <div>
                        <p className='font-bold mb-3 sm:mt-0 mt-8 flex gap-2 sm:justify-start justify-center items-center'>Token Talent, United Kingdon <img src={uk} className='w-5' /></p>
                        <p>13 Lytchett House, Freeland Park, Wareham road, Poole, Dorset, United Kingdom</p>
                    </div>
                </div>
                <div className='bg-white p-1'>
                    <div className='flex justify-center items-center'>
                        <p className='flex font-medium items-center justify-center gap-1.5 text-xs'>Made in<img src={pak} alt='' className='w-6' /> With <img src="https://i.pinimg.com/originals/c2/d5/3b/c2d53bc58a8b303f943340f3b14a4f03.png" alt='' className='w-5' /> For <img src="https://cdn-icons-png.flaticon.com/512/9985/9985721.png" alt='' className='w-5' /></p>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
