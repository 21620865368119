// App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Pages/HomePage/HomePage';
import FeaturesPage from './Pages/FeaturesPage/FeaturesPage';
import HiringPage from './Pages/HiringPage/HiringPage';
import DesktopNavbar from './Components/DesktopNavbar/DesktopNavbar';
import MobileNav from './Components/MobileNavbar/MobileNav';
import Footer from './Components/Footer/Footer';
import PolicyPage from './Pages/PolicyPage/PolicyPage';
import TermServices from './Pages/Terms&Services/TermServices';
import PricingPage from './Pages/PricingPage/PricingPage';
import AboutPage from './Pages/AboutPage/AboutPage';
import FAQsPage from './Pages/FAQsPage/FAQsPage';
import PeopleManagement from './Pages/Solution/PeopleManagement';
import TimeAttendance from './Pages/Solution/TimeAttendance';
import LeaveManagement from './Pages/Solution/LeaveManagement';
import PayrollManagement from './Pages/Solution/PayrollManagement';
import RemoteLocationManagement from './Pages/Solution/RemoteLocationManagement';
import Blogs from './Pages/Blogs/Blogs';
import SplashScreen from './Pages/SplashScreen/SplashScreen';
import { useEffect, useState } from 'react';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust the duration as needed

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className={`splash-screen ${!loading ? 'hidden' : ''}`}>
        <SplashScreen />
      </div>
      {!loading && (
        <Router>
          {/* Desktop Navbar for screens medium and up */}
          <div className='hidden md:block'>
            <DesktopNavbar />
          </div>

          {/* Mobile Navbar for screens below medium */}
          <div className="md:hidden">
            <MobileNav />
          </div>

          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/features" element={<FeaturesPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/blog" element={<Blogs />} />
            <Route path="/faqs" element={<FAQsPage />} />
            <Route path="/hiring" element={<HiringPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/privacypolicy" element={<PolicyPage />} />
            <Route path="/termsandCondition" element={<TermServices />} />
            <Route path="/people-management" element={<PeopleManagement />} />
            <Route path="/time-attendance" element={<TimeAttendance />} />
            <Route path="/leave-management" element={<LeaveManagement />} />
            <Route path="/payroll-management" element={<PayrollManagement />} />
            <Route path="/remote-location-management" element={<RemoteLocationManagement />} />
          </Routes>

          {/* Footer that shows on all screen sizes */}
          <Footer />
        </Router>
      )}
    </>
  );
}

export default App;
