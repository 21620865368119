import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../Assets/homepage/logo.svg';
import downArrow from '../../Assets/homepage/chevron-down.svg';
import upArrow from '../../Assets/homepage/chevron-up.svg';

const DesktopNavbar = () => {
    const navigate = useNavigate();
    const location = useLocation(); // useLocation to track route changes

    const [openDropdown, setOpenDropdown] = useState(null);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.dropdown')) {
                setOpenDropdown(null);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    // Close dropdown when route changes
    useEffect(() => {
        setOpenDropdown(null); // Close any open dropdown on route change
    }, [location.pathname]);

    // Function to toggle dropdowns, ensuring only one is open at a time
    const toggleDropdown = (dropdownName) => {
        if (openDropdown === dropdownName) {
            setOpenDropdown(null);  // Close dropdown if clicked again
        } else {
            setOpenDropdown(dropdownName);  // Open the clicked dropdown
        }
    };

    const changeColorDesktop = () => {
        if (location.pathname === "/" || location.pathname === "/features") {
            return { background: 'linear-gradient(90deg, #13114C, #5E61E7)' };
        } else {
            return { backgroundColor: 'white' };
        }
    };

    return (
        <>
            <div style={changeColorDesktop()} className="flex p-5 justify-between items-center">
                <div className='logo mx-5 px-2 py-0 bg-white shadow-md h-12 md:h-14 w-40 md:w-48 cursor-pointer rounded-full'>
                    <img onClick={() => { navigate('/') }} src={logo} alt="" />
                </div>
                <nav className="md:ml-auto ml-8 flex flex-wrap items-center shadow-md bg-white justify-center px-4 py-4 rounded-full text-xs">
                    <Link to="/features" className="mr-4 text-blue cursor-pointer">Features</Link>

                    <div className="relative dropdown">
                        <div
                            className="mr-4 text-blue flex items-center cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleDropdown('solutions');
                            }}
                        >
                            Solutions
                            <img
                                src={openDropdown === 'solutions' ? upArrow : downArrow}
                                alt="Arrow"
                                className='w-4'
                            />
                        </div>
                        {openDropdown === 'solutions' && (
                            <div className="absolute bg-white shadow-md text-blue rounded-lg p-2 mt-1 text-xs w-44 z-10">
                                <ul>
                                    <li onClick={() => navigate('/people-management')} className="p-2 text-blue hover:bg-gray-100 hover:rounded-lg cursor-pointer">People Management</li>
                                    <li onClick={() => navigate('/time-attendance')} className="p-2 hover:bg-gray-100 hover:rounded-lg cursor-pointer">Time & Attendance</li>
                                    <li onClick={() => navigate('/leave-management')} className="p-2 hover:bg-gray-100 hover:rounded-lg cursor-pointer">Leave Management</li>
                                    <li onClick={() => navigate('/payroll-management')} className="p-2 hover:bg-gray-100 hover:rounded-lg cursor-pointer">Payroll Management</li>
                                    <li onClick={() => navigate('/remote-location-management')} className="p-2 hover:bg-gray-100 hover:rounded-lg cursor-pointer">Remote Location</li>
                                </ul>
                            </div>
                        )}
                    </div>

                    <Link to="/pricing" className="mr-4 text-blue cursor-pointer">Pricing</Link>

                    <div className="relative dropdown">
                        <div
                            className="mr-4 text-blue flex items-center cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                toggleDropdown('resources');
                            }}
                        >
                            Resource
                            <img
                                src={openDropdown === 'resources' ? upArrow : downArrow}
                                alt="Arrow"
                                className='w-4'
                            />
                        </div>
                        {openDropdown === 'resources' && (
                            <div className="absolute bg-white shadow-md text-blue rounded-md p-2 mt-1 text-xs w-20 z-10">
                                <ul>
                                    {/* <li onClick={() => navigate('/blog')} className="p-2 hover:bg-gray-100 hover:rounded-lg cursor-pointer">Blogs</li> */}
                                    <li onClick={() => navigate('/about')} className="p-2 hover:bg-gray-100 hover:rounded-lg cursor-pointer">About</li>
                                    <li onClick={() => navigate('/faqs')} className="p-2 hover:bg-gray-100 hover:rounded-lg cursor-pointer">FAQs</li>
                                </ul>
                            </div>
                        )}
                    </div>

                    <Link target='_blank' rel='noopener noreferrer' to="https://app.tokentalent.co/login" className="mr-4 text-blue cursor-pointer">Login</Link>
                    <Link target='_blank' to="https://tokentalent.co/signup" rel='noopener noreferrer' className="mr-1 text-darkRed cursor-pointer">Get Started</Link>
                </nav>
            </div>
        </>
    );
};

export default DesktopNavbar;
