import React, { useEffect } from "react";
import { HiOutlineBriefcase } from "react-icons/hi";
import { FcOk } from "react-icons/fc";
import { Link } from 'react-router-dom';

function Pricing() {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    }, []);

    return (
        <div className=" md:overflow-x-hidden  ">
            <div class=" py-12 md:py-16 lg:py-20 mx-auto w-full max-w-8xl px-5 md:px-10 ">
                <div class="flex flex-col items-center justify-start max-[767px]:text-center ">
                    <div class="mb-16">
                        <div class="mx-auto max-w-3xl text-center">
                            <div class="mb-5">
                                <h2 class="font-bold text-3xl md:text-5xl text-indigo-900">Pricing</h2>
                            </div>
                            <p class="text-indigo-900">Fair and transparent pricing to help you grow
                            </p>
                        </div>
                    </div>
                    <div class="grid w-full max-[767px]:text-left grid-cols-1 md:grid-cols-4 gap-8 md:gap-4 ">

                        <div class="flex w-full max-w-[416px] flex-col items-start justify-between justify-self-start border border-solid border-[#e7eaf1] bg-gray-100 max-[767px]:mx-auto max-[767px]:text-left rounded-2xl gap-10 px-6 md:px-4 lg:px-8 py-10 md:py-8 lg:py-10 lg:gap-11">
                            <div class="flex flex-col gap-10 lg:gap-11">
                                <div class="flex flex-col items-start">
                                    <div class="mb-6">
                                        <div class="rounded-[80px] border border-solid border-[#e7eaf1] bg-[#e7eaf1] text-sm font-semibold uppercase text-[#002d40] py-1.5 pl-5 pr-6">
                                            <div>Startup Plans</div>
                                        </div>
                                    </div>
                                    <p class="text-lg text-[#575757] font-bold">Best for early stage startups</p>
                                    <p class="text-sm text-[#575757]">100% Free + 0$ Per Employee</p>
                                    <p class="text-xs text-[#575757]">Best for team of 0 - 9 Employees</p>
                                </div>
                                <h2 class="text-left font-bold text-3xl md:text-5xl">$0<span class="text-sm font-normal">/ month</span>
                                </h2>
                                <div class="flex flex-col gap-4">
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Employee Directory
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Leave Management
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Important Events
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Payroll Reports

                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">24/7 Help Center access
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <Link to="/signup" class="inline-block w-full border border-black hover:text-white hover:bg-indigo-500 px-6 text-center font-bold transition py-3 rounded-2xl">Get started</Link>
                        </div>




                        <div class="flex w-full max-w-[416px] flex-col items-start justify-between justify-self-start border border-solid border-[#e7eaf1] bg-gray-100 max-[767px]:mx-auto max-[767px]:text-left rounded-2xl gap-10 px-6 md:px-4 lg:px-8 py-10 md:py-8 lg:py-10 lg:gap-11">
                            <div class="flex flex-col gap-10 lg:gap-11">
                                <div class="flex flex-col items-start">
                                    <div class="mb-6">
                                        <div class="rounded-[80px] border border-solid border-[#e7eaf1] bg-[#e7eaf1] text-sm font-semibold uppercase text-[#002d40] py-1.5 pl-5 pr-6">
                                            <div>Silver Plans</div>
                                        </div>
                                    </div>
                                    <p class="text-lg text-[#575757] font-bold">Best for small Buisnesses</p>
                                    <p class="text-sm text-[#575757]">Monthly charges + 1$ Per Employee</p>
                                    <p class="text-xs text-[#575757]">Best for team of 0 - 30 Employees</p>
                                </div>
                                <h2 class="text-left font-bold text-3xl md:text-5xl">$49<span class="text-sm font-normal">/ month</span>
                                </h2>
                                <div class="flex flex-col gap-4">
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Employee Directory
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Basic Analytics</p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Business Analytics</p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Employee Master Data</p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Leave Management
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Important Events
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Digital Pay Slips

                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Payroll Reports

                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">24/7 Help Center access
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <Link to="/signup" class="inline-block w-full border border-black hover:text-white hover:bg-indigo-500 px-6 text-center font-bold transition py-3 rounded-2xl">Get started</Link>
                        </div>
                        <div class="flex w-full max-w-[416px] flex-col items-start justify-between justify-self-start border border-solid border-[#e7eaf1] bg-indigo-900 text-white max-[767px]:mx-auto max-[767px]:text-left rounded-2xl gap-10 px-6 md:px-4 lg:px-8 py-10 md:py-8 lg:py-10 lg:gap-11">
                            <div class="flex flex-col gap-10 lg:gap-11">
                                <div class="flex flex-col items-start">
                                    <div class="mb-6">
                                        <div class="rounded-[80px] bg-[#85ff43] text-sm font-semibold uppercase text-white py-1.5 pl-5 pr-6">
                                            <div className="text-black">GOLD PLANS</div>
                                        </div>
                                    </div>
                                    <p class="text-lg text-white font-bold">Best for medium-large businesses</p>
                                    <p class="text-sm text-[#ffffff]">Monthly charges + 1$ Per Employee</p>
                                    <p class="text-xs text-[#ffffff]">Best for team of 0 - 99 Employees</p>
                                </div>
                                <h2 class="text-left font-bold text-3xl md:text-5xl">$110<span class="text-sm font-normal">/ month</span>
                                </h2>
                                <div class="flex flex-col gap-4">
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">Employee Directory
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">Basic Analytics</p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">Business Analytics</p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">Employee Master Data</p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">Unlimited PTO Policies
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">Leave Management
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">Organization Chart

                                        </p>
                                    </div>




                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">End of Employment
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">Important Events
                                        </p>
                                    </div>

                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">Digital Pay Slips

                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">Payroll Data Backups

                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">Payroll Reports

                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">Priority Support


                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#ffffff]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#ffffff]">24/7 Help Center access
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <Link to="/signup" class="] inline-block w-full border-[#002d40] bg-indigo-500 px-6 text-center font-bold text-white transition hover:border-white hover:bg-white hover:text-[#07133b] py-3 rounded-2xl">Get started</Link>
                        </div>
                        <div class="flex w-full max-w-[416px] flex-col items-start justify-between justify-self-start border border-solid border-[#e7eaf1] bg-gray-100 max-[767px]:mx-auto max-[767px]:text-left rounded-2xl gap-10 px-6 md:px-4 lg:px-8 py-10 md:py-8 lg:py-10 lg:gap-11">
                            <div class="flex flex-col gap-10 lg:gap-11">
                                <div class="flex flex-col items-start">
                                    <div class="mb-6">
                                        <div class="rounded-[80px] border border-solid border-[#e7eaf1] bg-[#e7eaf1] text-sm font-semibold uppercase text-[#002d40] py-1.5 pl-5 pr-6">
                                            <div>Platinum Plans</div>
                                        </div>
                                    </div>
                                    <p class="text-lg text-[#575757] font-bold">Best for large businesses

                                    </p>
                                    <p class="text-sm text-[#575757]">Monthly charges + 0.75$ / Employee (25% Discount)</p>
                                    <p class="text-xs text-[#575757]">Best for team of 0 - 1000+ Employees</p>

                                </div>
                                <h2 class="text-left font-bold text-3xl md:text-5xl">$250<span class="text-sm font-normal">/ month</span>
                                </h2>
                                <div class="flex flex-col gap-4">
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Employee Directory
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Basic Analytics</p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Business Analytics</p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Employee Master Data</p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Leave Management
                                        </p>
                                    </div><div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#1c2a59]">Unlimited Payroll Runs


                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#1c2a59]">Access to Upcoming Benefits Suite


                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#1c2a59]">Important Events
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#1c2a59]">Progression + Upskilling
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Organization Chart

                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">End of Employment
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Important Events
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Progression + Upskilling
                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Digital Pay Slips

                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Payroll Data Backups

                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">Payroll Reports

                                        </p>
                                    </div><div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#1c2a59]">Priority Support


                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#1c2a59]">Dedicated Support Manager


                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#1c2a59]">Priority Access to new Features


                                        </p>
                                    </div>
                                    <div class="flex flex-row items-center gap-2.5 text-left text-sm">
                                        <div class="flex w-4 flex-row items-center text-[#1c2a59]">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 0.666992C3.94935 0.666992 0.666016 3.95033 0.666016 8.00033C0.666016 12.0503 3.94935 15.3337 7.99935 15.3337C12.0493 15.3337 15.3327 12.0503 15.3327 8.00033C15.3327 3.95033 12.0493 0.666992 7.99935 0.666992ZM11.178 6.76033C11.2365 6.69342 11.2811 6.61548 11.3091 6.5311C11.337 6.44672 11.3478 6.3576 11.3409 6.26898C11.3339 6.18036 11.3092 6.09403 11.2684 6.01508C11.2276 5.93612 11.1713 5.86613 11.103 5.80922C11.0348 5.75231 10.9558 5.70963 10.8707 5.6837C10.7857 5.65777 10.6964 5.6491 10.6079 5.65821C10.5195 5.66732 10.4338 5.69402 10.3558 5.73675C10.2779 5.77947 10.2093 5.83735 10.154 5.90699L7.28735 9.34632L5.80402 7.86233C5.67828 7.74089 5.50988 7.67369 5.33508 7.67521C5.16028 7.67673 4.99307 7.74684 4.86947 7.87045C4.74586 7.99405 4.67575 8.16126 4.67423 8.33606C4.67271 8.51086 4.73991 8.67926 4.86135 8.80499L6.86135 10.805C6.92685 10.8705 7.00528 10.9216 7.09163 10.955C7.17797 10.9885 7.27035 11.0036 7.36286 10.9994C7.45538 10.9952 7.546 10.9718 7.62896 10.9306C7.71191 10.8895 7.78538 10.8315 7.84468 10.7603L11.178 6.76033Z" fill="currentColor"></path>
                                            </svg>
                                        </div>
                                        <p class="text-[#575757]">24/7 Help Center access
                                        </p>
                                    </div>

                                </div>
                            </div>
                            <Link to="/signup" class="inline-block w-full border border-black hover:text-white hover:bg-indigo-500 px-6 text-center font-bold text-black transition py-3 rounded-2xl">Get started</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-5 md:px-10">
                <div className="mx-auto w-full max-w-7xl">
                    <div className="py-5 md:py-2 lg:py-5">
                        <div className="mx-auto flex-col flex max-w-3xl items-center text-center mb-3 md:mb-12 lg:mb-16">
                            <p className="text-sm font-semibold uppercase text-indigo-900">4 easy steps</p>
                            <h2 className="font-bold text-3xl md:text-5xl text-indigo-900">How it works</h2>
                            <div className="mx-auto mt-4 max-w-[528px] mb-5 md:mb-6 lg:mb-8">
                            </div>
                        </div>
                        <div className="relative grid items-center max-[991px] justify-items-start grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12">
                            <div className="relative flex-col flex h-full items-start">
                                <a className="relative mb-8 flex w-auto max-w-[496px] cursor-pointer items-start justify-center rounded-xl gap-4 border border-solid bg-white text-left align-top text-[#222222] px-6 py-5" tabIndex="-1" href="#w-tabs-0-data-w-pane-0">
                                    <div className="flex-col flex-none flex h-10 w-10 items-center justify-center bg-[#dae2f3]">
                                        <p className="font-bold text-[#7c8aaa]">1</p>
                                    </div>
                                    <div className="ml-4 flex-col flex-initial flex w-auto items-start gap-2">
                                        <h5 className="font-bold text-base md:text-md">Sign Up</h5>
                                        <p className="text-[#636262] text-sm">Begin your journey by creating an account. Fill in your details to set up your personalized profile.</p>
                                    </div>
                                </a>
                                <a className="relative mb-8 flex w-auto max-w-[496px] cursor-pointer items-start rounded-xl justify-center gap-4 border border-solid bg-white text-left align-top text-[#222222] px-6 py-5" tabIndex="-1" href="#w-tabs-0-data-w-pane-1">
                                    <div className="flex-col flex-none flex h-10 w-10 items-center justify-center bg-[#dae2f3]">
                                        <p className="font-bold text-[#7c8aaa]">2</p>
                                    </div>
                                    <div className="ml-4 flex-col flex-initial flex w-auto items-start gap-2">
                                        <h5 className="font-bold text-base md:text-md">Payment</h5>
                                        <p className="text-[#636262] text-sm">Choose your preferred payment method and make secure transactions hassle-free within the app.</p>
                                    </div>
                                </a>
                                <a className="relative mb-8 flex-row flex w-auto max-w-[496px] cursor-pointer items-start justify-center rounded-xl gap-4 border border-solid bg-white text-left align-top text-[#222222] px-6 py-5" href="#w-tabs-0-data-w-pane-2">
                                    <div className="flex-col flex-none flex h-10 w-10 items-center justify-center bg-[#dae2f3]">
                                        <p className="font-bold text-[#7c8aaa]">3</p>
                                    </div>
                                    <div className="ml-4 flex-col flex-initial flex w-auto items-start gap-2">
                                        <h5 className="font-bold text-base md:text-md">Account Verification</h5>
                                        <p className="text-[#636262] text-sm">Ensure account security through our robust verification process. Your safety is our priority; your details will be carefully validated.</p>
                                    </div>
                                </a>
                                <a className="relative mb-8 flex-row flex w-auto max-w-[496px] cursor-pointer items-start justify-center rounded-xl gap-4 border border-solid bg-white text-left align-top text-[#222222] px-6 py-5" href="#w-tabs-0-data-w-pane-2">
                                    <div className="flex-col flex-none flex h-10 w-10 items-center justify-center bg-[#dae2f3]">
                                        <p className="font-bold text-[#7c8aaa]">4</p>
                                    </div>
                                    <div className="ml-4 flex-col flex-initial flex w-auto items-start gap-2">
                                        <h5 className="font-bold text-base md:text-md">Done</h5>
                                        <p className="text-[#636262] text-sm">Congratulations! You've successfully completed the steps. You are now ready to enjoy the full functionality of our app. Start exploring!</p>
                                    </div>
                                </a>
                            </div>
                            <div className="relative block overflow-hidden">
                                <div className="relative block">
                                    <img src="https://assets.website-files.com/647e296b89c00bcfafccf696/647f0b14162c5306c7cfdcc9_Rectangle%20106.jpg" alt="Image showing dashboard of the app" className="inline-block h-full w-full max-w-full" />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    );
}

export default Pricing;


