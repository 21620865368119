import { useEffect, useRef } from 'react';

const BlogPage = () => {
    const iframeRef = useRef(null);

    useEffect(() => {
        const iframe = iframeRef.current;

        const handleIframeResize = () => {
            if (iframe) {
                iframe.style.height = `${window.innerHeight - -300}px`; // Adjust to fit the viewport
            }
        };

        // Handle initial iframe height adjustment
        handleIframeResize();

        // Listen for window resize events to adjust iframe dynamically
        window.addEventListener('resize', handleIframeResize);

        return () => {
            window.removeEventListener('resize', handleIframeResize);
        };
    }, []);

    return (
        <div>
            <iframe
                ref={iframeRef}
                src="http://35.186.167.25"
                width="100%"
                height="100%"
                scrolling="no"
            ></iframe>
        </div>
    );
};

export default BlogPage;
