import React, { useEffect } from 'react';

const PolicyPage = () => {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    }, []);

    return (
        <>
            <div className="w-full">
                <div className="max-w-4xl mx-auto p-6 md:p-10 flex flex-col gap-6">
                    <div className="flex flex-col justify-center items-center text-center p-5">
                        <h2 className="text-darkBlue font-bold text-3xl md:text-4xl">
                            Token Talent's Privacy Policy
                        </h2>
                        <p className="text-darkBlue mt-2 text-sm">
                            Last updated: September 1, 2023
                        </p>
                    </div>
                    <h2 className="font-bold text-2xl md:text-2xl">Introduction</h2>
                    <p className="text-sm">
                        Welcome to Token Talent! At Token Talent, we are committed to protecting your privacy and providing you with a secure online experience. This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you visit our website, tokentalent.co (referred to as “the Website”). By accessing or using the Website, you agree to the practices described in this Privacy Policy.
                    </p>

                    <h2 className="font-bold text-2xl md:text-2xl">Information We Collect</h2>
                    <ul className="list-disc pl-5 space-y-2 text-sm">
                        <li>Personal Information: We may collect personal information, such as your name, email address, phone number, and other contact details, when you sign up for our services, subscribe to newsletters, or contact us.</li>
                        <li>Usage Information: We may collect information about how you use the Website, including your IP address, browser type, pages visited, and the time and date of your visits.</li>
                        <li>Cookies and Tracking Technologies: We use cookies and similar tracking technologies to enhance your browsing experience and gather data about your interactions with our Website. You can manage your cookie preferences through your browser settings.</li>
                    </ul>

                    <h2 className="font-bold text-2xl md:text-2xl mt-5">How We Use Your Information</h2>
                    <ul className="list-disc pl-5 space-y-2 text-sm">
                        <li>To provide you with the services you requested.</li>
                        <li>To improve and personalize your experience on the Website.</li>
                        <li>To communicate with you, including responding to your inquiries and sending you updates.</li>
                        <li>To analyze and monitor the usage of our Website.</li>
                        <li>To protect the security and integrity of our Website.</li>
                    </ul>

                    <h2 className="font-bold text-2xl md:text-2xl mt-5">Your Choices</h2>
                    <ul className="list-disc pl-5 space-y-2 text-sm">
                        <li>Access and Update: Access, correct, or update your personal information by logging into your account or contacting us directly.</li>
                        <li>Unsubscribe: Opt-out of receiving marketing communications from us by following the instructions provided in our emails or contacting us.</li>
                    </ul>

                    <h2 className="font-bold text-2xl md:text-2xl mt-5">Security</h2>
                    <p className="text-sm">
                        We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is entirely secure. Therefore, we cannot guarantee absolute security.
                    </p>

                    <h2 className="font-bold text-2xl md:text-2xl mt-5">Children's Privacy</h2>
                    <p className="text-sm">
                        Our Website is not intended for children under the age of 13. We do not knowingly collect or maintain personal information from children.
                    </p>

                    <h2 className="font-bold text-2xl md:text-2xl mt-5">Changes to this Privacy Policy</h2>
                    <p className="text-sm">
                        We may update this Privacy Policy from time to time. The latest version will be posted on the Website with the effective date. Your continued use of the Website following any changes constitutes your acceptance of those changes.
                    </p>

                    <h2 className="font-bold text-2xl md:text-2xl mt-5">Contact Us</h2>
                    <p className="text-sm">
                        If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at cs@tokentalent.co.
                    </p>
                </div>
            </div>
        </>
    )
}

export default PolicyPage;
