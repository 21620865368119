import React, { useEffect } from 'react';

const TermServices = () => {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    }, []);

    return (
        <>
            <div className="w-full">
                <div className="max-w-4xl mx-auto p-6 md:p-10 flex flex-col gap-6">
                    <div className="flex flex-col justify-center items-center text-center p-5">
                        <h2 className="font-bold text-3xl md:text-4xl text-darkBlue">
                            Token Talent's Terms and Conditions
                        </h2>
                        <p className="mt-2 text-darkBlue text-center">
                            Last updated: 9 February, 2024
                        </p>
                    </div>
                    <h2 className="font-bold text-xl md:text-2xl">Introduction</h2>
                    <p className="text-sm md:sm">
                        Welcome to Token Talent! These Terms and Conditions (“Terms”) govern your use of our website, tokentalent.co (referred to as “the Website”). By accessing or using the Website, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please refrain from using our Website.
                    </p>

                    <h2 className="font-bold text-xl md:text-2xl">Use of Website</h2>
                    <ul className="list-disc list-inside text-xs md:text-sm">
                        <li>
                            <strong>Eligibility:</strong> You must be at least 13 years old to use this Website. If you are under 13, please do not use our services.
                        </li>
                        <li>
                            <strong>User Account:</strong> To access certain features of the Website, you may need to create a user account. You are responsible for maintaining the confidentiality of your account information and are liable for all activities that occur under your account.
                        </li>
                        <li>
                            <strong>User Content:</strong> You may submit content, including text, images, and other materials (“User Content”) on the Website. By submitting User Content, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, modify, adapt, publish, and display the User Content.
                        </li>
                    </ul>

                    <h2 className="font-bold text-xl md:text-2xl">File and Image Access Permission</h2>
                    <p className="text-sm md:text-sm">
                        Token Talent App may require access to files and images on your device for certain functionalities to operate effectively. This access is essential for features such as uploading important documents and updating profile images within the app. By using the Token Talent App, you consent to granting the necessary permissions for accessing files and images stored on your device.
                    </p>

                    <h2 className="font-bold text-xl md:text-2xl">Location Access Permission</h2>
                    <p className="text-sm md:text-sm">
                        Token Talent App may require access to your device's location for certain functionalities to operate effectively. This access is essential for features such as ensuring employees are inside the office premises before allowing them to mark attendance. By using the Token Talent App, you consent to granting the necessary permissions for accessing your device's location.
                    </p>

                    <h2 className="font-bold text-xl md:text-2xl">Intellectual Property</h2>
                    <p className="text-sm md:text-sm">
                        The Website and its content, including but not limited to text, graphics, logos, images, software, and trademarks, are the property of Token Talent and are protected by intellectual property laws. You may not use, reproduce, or distribute any content from the Website without prior authorization.
                    </p>

                    <h2 className="font-bold text-xl md:text-2xl">Disclaimer of Warranties</h2>
                    <p className="text-sm md:text-sm">
                        The Website is provided “as is” and “as available” without any warranties of any kind, whether express or implied. We do not warrant that the Website will be error-free, uninterrupted, secure, or meet your requirements.
                    </p>

                    <h2 className="font-bold text-xl md:text-2xl">Limitation of Liability</h2>
                    <p className="text-sm md:text-sm">
                        Token Talent, its affiliates, officers, directors, and employees shall not be liable for any indirect, consequential, special, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, arising from your use of the Website.
                    </p>

                    <h2 className="font-bold text-xl md:text-2xl">Indemnification</h2>
                    <p className="text-sm md:text-sm">
                        You agree to indemnify and hold Token Talent harmless from any claims, losses, damages, liabilities, costs, and expenses (including attorney’s fees) arising out of your use of the Website or violation of these Terms.
                    </p>

                    <h2 className="font-bold text-xl md:text-2xl">Termination</h2>
                    <p className="text-sm md:text-sm">
                        We reserve the right to terminate or suspend your access to the Website at our discretion, without notice, for any violation of these Terms or for any other reason.
                    </p>

                    <h2 className="font-bold text-xl md:text-2xl">Governing Law</h2>
                    <p className="text-sm md:text-sm">
                        These Terms shall be governed by and construed in accordance with the laws of the United States of America, without regard to its conflict of law principles.
                    </p>

                    <h2 className="font-bold text-xl md:text-2xl">Changes to Terms</h2>
                    <p className="text-sm md:text-sm">
                        We may update these Terms from time to time. The latest version will be posted on the Website with the effective date. Your continued use of the Website following any changes constitutes your acceptance of those changes.
                    </p>

                    <h2 className="font-bold text-xl md:text-2xl">Contact Us</h2>
                    <p className="text-sm md:text-sm">
                        If you have any questions, concerns, or requests regarding these Terms, please contact us at info@tokentalent.co
                    </p>
                </div>
            </div>
        </>
    );
};

export default TermServices;
