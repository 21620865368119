import { useLocation } from 'react-router-dom';
import './Header.css';

const Header = ({ heading, text, homeImage, featureImage }) => {
    const location = useLocation();

    // Check if we are on the home or feature page based on the current route
    const isHomePage = location.pathname === '/';
    const isFeaturePage = location.pathname === '/features';

    return (
        <>
            <header className='header w-full pb-12 md:pb-24'>
                <div className='flex flex-col sm:flex-row justify-between md:items-center'>

                    <div className='text-white md:text-left text-center p-8 md:p-16 flex flex-col gap-6 md:gap-6 mx-0 md:mx-12 min-[2000px]:mx-48'>
                        {isHomePage && (
                            <>
                                <h1 data-aos="fade-right" data-aos-duration="1000" className='text-2xl md:text-4xl min-[2000px]:text-6xl font-bold'>{heading}</h1>
                                <p data-aos="fade-right" data-aos-duration="1000" className='text-xs w-full md:max-w-[22rem] min-[2000px]:text-xl lg:text-sm font-light'>{text}</p>

                                <div className='flex gap-3 md:justify-start justify-center' data-aos="fade-up" data-aos-duration="1000">
                                    <a target='_blank' rel='noopener noreferrer' href="https://tokentalent.co/signup">
                                        <button
                                            className='bg-white text-blue w-28 md:w-32 p-2 md:p-3 rounded-full text-xs font-medium'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.open("https://tokentalent.co/signup", "_blank");
                                            }}
                                        >
                                            Sign Up Today
                                        </button>
                                    </a>

                                    <a target='_blank' rel='noopener noreferrer' href="https://calendly.com/demofortokentalent/token-talent-demo?month=2024-08">
                                        <button
                                            className='bg-darkBlue text-white w-28 md:w-32 p-2 md:p-3 rounded-full text-xs font-normal'
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.open("https://calendly.com/demofortokentalent/token-talent-demo?month=2024-08", "_blank");
                                            }}
                                        >
                                            Get Demo
                                        </button>
                                    </a>
                                </div>
                            </>
                        )}
                        {isFeaturePage && (
                            <>
                                <h1 className='text-2xl md:text-4xl min-[2000px]:text-6xl font-bold'>{heading}</h1>
                                <p className='text-xs w-full md:max-w-[22rem] min-[2000px]:text-xl lg:text-sm font-light'>{text}</p>

                                <div className='flex gap-4 text-center md:justify-start justify-center'>
                                    <button className='bg-white text-blue w-32 md:w-44 p-2 rounded-2xl text-3xl md:text-4xl font-extrabold'>
                                        100k+ <br /> <span className='text-xs md:text-sm font-medium'>employees</span>
                                    </button>
                                    <button className='bg-darkBlue text-white w-32 md:w-44 p-2 md:p-3 rounded-2xl text-3xl md:text-4xl font-extrabold'>
                                        20k+ <br /> <span className='text-xs md:text-sm font-medium'> Payroll Run/Month </span>
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                    {isHomePage && (
                        <div className='pt-0 md:pt-10 flex justify-end' data-aos="fade-left" data-aos-duration="1000">
                            <img src={homeImage} alt="" className='xl:w-full min-[1300px]:max-w-lg max-[800px]:w-100 max-[500px]:max-w-sm max-[380px]:w-72' />
                        </div>
                    )}
                    {isFeaturePage && (
                        <div className='flex items-center justify-center'>
                            <img src={featureImage} alt="" className='xl:w-full min-[1300px]:max-w-md max-[800px]:w-100 max-[500px]:max-w-sm max-[380px]:w-72 mr-0 md:mr-5' />
                        </div>
                    )}
                </div>
            </header>
        </>
    );
};

export default Header;