import React from 'react'
import team from '../../../Assets/homepage/international-Teams 2.png'
import './HomeInternationTeam.css'
import { useNavigate } from 'react-router-dom'

const HomeInternationTeam = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='team flex flex-col md:flex-row justify-between p-10'>
                <div className='flex flex-col gap-4 justify-center w-full mx-0 md:mx-12'>
                    <h1 className='md:text-4xl text-2xl text-darkBlue font-bold md:text-left text-center'>Build your <br /> international team <br /> with ease</h1>
                    <p className='text-xs font-light text-darkBlue md:text-left text-center'>Find the best talent - hire, manage, and pay them all with Token Talent</p>
                    <button onClick={() => navigate('/features')} className='bg-darkBlue p-3 text-white md:m-0 m-auto w-32 rounded-full text-xs font-normal'>
                        See Difference
                    </button>
                </div>
                <div className='flex w-full md:justify-end justify-center items-center md:pt-0 pt-5 mr-0 md:mr-12'>
                    <img src={team} className='globe max-[800px]:w-60 max-[500px]:w-60 max-[380px]:w-48 md:max-w-xs xl:max-w-sm' />
                </div>
            </div>
        </>
    )
}
export default HomeInternationTeam
