import React from 'react'
import whiteArrow from '../../../Assets/featuresPage/whiteArrow.svg'
import redArrow from '../../../Assets/featuresPage/redArrow.svg'
import greenArrow from '../../../Assets/featuresPage/greenArrow.svg'
import blueArrow from '../../../Assets/featuresPage/blueArrow.svg'
import orangeArrow from '../../../Assets/featuresPage/orangeArrow.svg'
import './GlobalHrPlatform.css'

const GlobalHrPlatform = () => {
    return (
        <>
            <div className='bg-lightGray p-6 md:p-10 md:-mt-[8rem] -mt-[11rem]'>
                <div className='pt-[16rem] md:pt-[18rem] flex flex-col md:flex-row justify-between gap-8'>
                    <div className='w-full md:w-1/2 flex flex-col gap-5 justify-center'>
                        <h1 className='font-bold text-2xl md:text-3xl text-center md:text-left'>
                            The <span className='text-blue'>global HR platform</span> <br /> you deserve
                        </h1>
                        <p className='text-xs md:text-xs max-w-sm md:text-left text-center'>
                            Complete your to-do list without opening a second tab. The only all-in-one platform
                            you need to manage your entire team from Marseille to Manilla. Enjoy the ultimate
                            flexibility by managing all your global HR with Remote. EOR employees in Estonia? Payroll in Peru?
                            Contractors in Costa Rica? It's all in one place, so you can spend less time tracking down documents
                            and more time giving your global team the experience they deserve.
                        </p>
                        <div className='flex justify-center md:justify-start'>
                            <button className='bg-darkBlue text-white py-2 px-4 md:py-3 md:px-6 rounded-full text-xs font-normal self-start'>
                                Explore the Platform
                            </button>
                        </div>
                    </div>
                    <div className='w-full md:w-1/2 grid grid-cols-1 gap-4'>
                        <div id='card1' className='rounded-xl p-4 shadow-lg card'>
                            <div className='flex justify-between items-center mb-3'>
                                <h1 className='text-xs font-semibold'>Management</h1>
                                <img src={blueArrow} alt="Blue Arrow" className='ColorArrow w-3 h-3' />
                                <img src={whiteArrow} alt="White Arrow" className='Arrow w-4 h-4' />
                            </div>
                            <p className='text-xs'>
                                The only all-in-one platform you need to manage your entire team <br className='hidden md:block' />
                                from Marseille to Manilla.
                            </p>
                        </div>
                        <div id='card2' className='rounded-xl p-4 shadow-lg card'>
                            <div className='flex justify-between items-center mb-3'>
                                <h1 className='text-xs font-semibold'>Management</h1>
                                <img src={redArrow} alt="Red Arrow" className='ColorArrow w-3 h-3' />
                                <img src={whiteArrow} alt="White Arrow" className='Arrow w-4 h-4' />
                            </div>
                            <p className='text-xs'>
                                The only all-in-one platform you need to manage your entire team <br className='hidden md:block' />
                                from Marseille to Manilla.
                            </p>
                        </div>
                        <div id='card3' className='rounded-xl p-4 shadow-lg card'>
                            <div className='flex justify-between items-center mb-3'>
                                <h1 className='text-xs font-semibold'>Management</h1>
                                <img src={greenArrow} alt="Green Arrow" className='ColorArrow w-3 h-3' />
                                <img src={whiteArrow} alt="White Arrow" className='Arrow w-4 h-4' />
                            </div>
                            <p className='text-xs'>
                                The only all-in-one platform you need to manage your entire team <br className='hidden md:block' />
                                from Marseille to Manilla.
                            </p>
                        </div>
                        <div id='card4' className='rounded-xl p-4 shadow-lg card'>
                            <div className='flex justify-between items-center mb-3'>
                                <h1 className='text-xs font-semibold'>Management</h1>
                                <img src={orangeArrow} alt="Orange Arrow" className='ColorArrow w-3 h-3' />
                                <img src={whiteArrow} alt="White Arrow" className='Arrow w-4 h-4' />
                            </div>
                            <p className='text-xs'>
                                The only all-in-one platform you need to manage your entire team <br className='hidden md:block' />
                                from Marseille to Manilla.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GlobalHrPlatform
