import React, { useEffect } from 'react'
import HeaderSection from './HeaderSection/HeaderSection'
import Footer from '../../Components/Footer/Footer'
import WhyChooseTTSection from './WhyChooseTTSection/WhyChooseTTSection'
import UserNeedCardsSec from './UserNeedCardsSec/UserNeedCardsSec'
import GlobalHrPlatform from './GlobalHrPlatform/GlobalHrPlatform'
import laptopImg from '../../Assets/featuresPage/Macbook_Air_Mockup_1 3.png'
import OnboardSection from './OnboardSection/OnboardSection'
import TourSection from './TourSection/TourSection'
import LastSection from './LastSection/LastSection'

const FeaturesPage = () => {

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    }, []);

    return (
        <>
            <HeaderSection />
            <WhyChooseTTSection />
            <UserNeedCardsSec />
            <div className='flex justify-center items-center'>
                <img src={laptopImg} alt="" className='w-full md:max-w-2xl relative md:top-[6rem] top-[2rem]' />
            </div>
            <GlobalHrPlatform />
            <OnboardSection />
            <TourSection />
            <LastSection />
        </>
    )
}

export default FeaturesPage
