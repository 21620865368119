import React from 'react'
import choose from '../../../Assets/featuresPage/chooseSection-img.png'

const WhyChooseTTSection = () => {
    return (
        <>
            <div className='bg-lightGray flex flex-col lg:flex-row justify-between p-10 md:p-20 items-center gap-10'>
                <div className='flex justify-center mb-10 md:mb-0'>
                    <img src={choose} alt='' className='max-w-xs md:max-w-md' />
                </div>
                <div className='flex flex-col gap-5 md:gap-4 items-center md:items-start text-center md:text-left'>
                    <h1 className='text-3xl md:text-4xl text-black font-bold'>
                        Why Choose <span className='text-blue'>Token</span> <span className='text-lightPurple'>Talent?</span>
                    </h1>
                    <p className='text-sm md:text-xs max-w-lg'>
                        At Token Talent, we understand that finding the right talent for your organization or the perfect job opportunity can be a challenging task. That's why we've developed a platform that sets us apart from the rest. With our commitment to excellence and innovation, we're here to make your talent journey seamless and successful.
                    </p>
                    <p className='font-semibold text-sm text-blue'>Stay on top of your tasks like never before.</p>
                    <a target='_blank' rel='noopener noreferrer' href="https://tokentalent.co/signup">
                        <button onClick={(e) => {
                            e.preventDefault();
                            window.open("https://tokentalent.co/signup", "_blank");
                        }} className='bg-blue text-white w-32 p-2 sm:p-3 rounded-full text-xs font-medium'>
                            Get Started
                        </button>
                    </a>
                </div>
            </div>
        </>
    )
}
export default WhyChooseTTSection
